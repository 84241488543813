
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { AppUserClient, NotificationClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import { purchaseAndroid, purchaseIos } from '@/config';
import { PurchasesServices } from '@/services/PurchasesServices';
import bodymovin, { AnimationItem } from 'lottie-web';
import store from '@/store';
import { WSSServices } from '@/services/WSSServices';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';
import { isNewMesasgeNotification, newNotificationHasArrived, pushNewNotification } from '@/services/Firebase/FirebasePushServices';

@Options({})
export default class LoggedUserLayout extends Vue {

    experienceAnimation: AnimationItem = null;

    created() {
        
        if(!store.state.loggedUser.identifier)
            return;
        
        this.init();
    }

    init(){
        WSSServices.connect(this.onNotificationReceived, this.onNewWssError, this.onWSSCreated)

        Promise.all([
            NotificationClient.getUnreadNotificationNumber(false),
            NotificationClient.getUnreadAffinityNotificationNumber(false),
        ])
        .then( x => {
            store.state.notificationNumber = x[0];
            store.state.affinityNotificationNumber = x[1];
        })
    }

    onNewWssError(error: string){
        this.$opModal.show(InfoModal, {
            img: "face_error.svg",
            text: error,
            confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
            confirmCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    onNotificationReceived(val: string) {
        var notification: OM.NotificationVM = JSON.parse(val);

       pushNewNotification(notification);
    }

    onWSSCreated(){
        if(!store.state.loggedUser.identifier){
            WSSServices.disconnect();
            return;
        }
        
        document.addEventListener("resume", this.resumeCallback, false);
        document.addEventListener("pause", this.pauseCallback, false);
    }

    resumeCallback(){
        this.init();
    }

    pauseCallback(){
        WSSServices.disconnect();
    }

    mounted(){
        PurchasesServices.configure();
        this.notificationChanged();
        this.loadAnimation();

        this.changedPath();
    }

    @Watch("$route.fullPath")
    changedPath(){
        if(this.$route.meta.hidefooter)
            this.experienceAnimation = null;

        if(this.$route.fullPath.indexOf("experience") > -1 && !this.$route.meta.hidefooter) {
            setTimeout(() => {
                if(!this.experienceAnimation)
                    this.loadAnimation();

                this.experienceAnimation.stop()
                this.experienceAnimation.play();
            }, 50);
        }
    }

    loadAnimation(){
        this.experienceAnimation = bodymovin.loadAnimation({
            container: <any>this.$refs.lottieAnimation,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: './json/experience.json',
        })
    }

    get isIos(){
        return window.cordova.platformId === 'ios';
    }
    get isAndroid(){
        return window.cordova.platformId === 'android';
    }

    @Watch("$store.state.notificationFunction")
    notificationChanged(){
        if(store.state.notificationFunction)
            store.state.notificationFunction();
    }

    beforeUnmount(){
        this.pauseCallback();
        document.removeEventListener("resume", this.resumeCallback, false);
        document.removeEventListener("pause", this.pauseCallback, false);
    }
}
