import { CommonServices } from '@/services/CommonServices';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { purchaseAndroid, purchaseIos } from '@/config';
import store from '@/store';
import { PremiumClient } from './Services';

class _PurchasesServices {
    
    configure(){
        if(!window.cordova)
            return;

        if (window.cordova.platformId === 'ios') {
            Purchases.configure(purchaseIos, store.state.loggedUser.identifier)
        } else if (window.cordova.platformId === 'android') {
            Purchases.configure(purchaseAndroid, store.state.loggedUser.identifier);
        }
    }

    getCustomerInfoAndUpdate() {
        let prom = new Promise<void>(async (resolve, reject) => {
            if(!window.cordova){
                resolve();
                return;
            }

            await Purchases.getCustomerInfo(
                async customerInfo => {
                    var customerInfoVM = new OM.CustomerInfoVM();

                    customerInfoVM.provider = VM.PremiumPurchasedProvider.Android;

                    if (window.cordova.platformId === 'ios')
                        customerInfoVM.provider = VM.PremiumPurchasedProvider.Apple;

                    var actives = customerInfo.entitlements.active;

                    var keys = Object.keys(actives);

                    keys.forEach( key => {
                        var el = actives[key];

                        var toAdd: OM.PurchaseListVM = {
                            expirationDate: el.expirationDate,
                            isActive: el.isActive, 
                            latestPurchaseDate: el.latestPurchaseDate,
                            productId: el.productIdentifier,
                            subscriptionId: el.identifier,
                            willRenew: el.willRenew
                        }

                        customerInfoVM.list.push(toAdd);
                    })
                    
                    await PremiumClient.checkPremiumPlan(customerInfoVM, false);
                    resolve();
                    // access latest customerInfo
                },
                error => {
                    reject(error);
                    // Error fetching customer info
                }
            );

            resolve();
        })
        return prom;
    }

}

export let PurchasesServices = new _PurchasesServices();